import React from 'react';

const CertyUpExact = () => {
    return (
        <div className="certyup-container">
            {/* Colonne image */}
            <div className="certyup-image">
                <div className="image-wrapper">
                    <div className="cropped-image-container">
                        <img
                            src="/img/etudiant.png"
                            alt="Étudiant souriant avec un dossier et un sac à dos"
                            className="cropped-student-image"
                        />
                    </div>
                    <img
                        src="/img/Frame.png"
                        alt="100% en ligne et accessibles partout"
                        className="overlay-image"
                    />
                </div>
            </div>

            {/* Colonne texte */}
            <div className="certyup-content">
                <div className="title-box">
                    <h1>CertyUp, votre passerelle</h1>
                    <h2>vers l'employabilité !</h2>
                </div>

                <div className="description-box">
                    <p>
                        CertyUp est une plateforme de formation en ligne spécialisée dans les micro-certifications et les formations courtes certifiantes.
                    </p>
                </div>

                <div className="subtitle-box">
                    <h3>Pourquoi choisir CertyUp ?</h3>
                </div>

                <div className="advantages">
                    <div className="advantage-card"> {/* Utiliser cette classe pour obtenir l'effet de carte */}
                        <div className="advantage-box">
                            <div className="icon">✔</div>
                            <p>Formations courtes et adaptées au marché</p>
                        </div>
                    </div>

                    <div className="advantage-card">
                        <div className="advantage-box">
                            <div className="icon">✔</div>
                            <p>Certifications reconnues</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertyUpExact;

