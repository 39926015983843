import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './Formation.scss'; // Assurez-vous de créer ce fichier avec le SCSS fourni

const Formation = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Define your base URL here
  const baseURL = process.env.REACT_APP_BASE_URL;
  

  useEffect(() => {
    // Fetch courses when component mounts
    fetchCourses();
    console.log(baseURL );
  }, []);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/api/cours`);
      console.log('API response:', response.data);
      
      // Check if response has the new structure with 'cours' property
      const coursesData = response.data.cours || response.data;
      setCourses(coursesData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching courses:', err);
      console.error('Response:', err.response?.data);
      setError(`Failed to fetch courses: ${err.message}`);
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="formation-container"><div className="loading-message">Loading courses...</div></div>;
  }

  if (error) {
    return <div className="formation-container"><div className="error-message">{error}</div></div>;
  }

  if (courses.length === 0) {
    return <div className="formation-container"><div className="no-courses-message">No courses available.</div></div>;
  }

  return (
    <div className="formation-container">
      {/* <h1>Nos Formations</h1> */}
      <div className="courses-grid">
        {courses.map(course => (
          <div key={course.id} className="course-card">
            <div className="course-image-container">
              {course.image ? (
                <img
                  src={`${baseURL}/images/${course.image}`}
                  alt={course.title}
                />
              ) : (
                <img
                  src={`${baseURL}/images/default-course.jpg`}
                  alt="Default course"
                />
              )}
            </div>
            <div className="course-content">
              <h2 className="course-title">{course.title}</h2>
              {course.theme && (
                <div className="course-theme">
                  {course.theme.name}
                </div>
              )}
              {/* <div className="course-level">
                {course.niveau || 'Tous niveaux'}
              </div>
              <p className="course-description">
                {course.description || 'Découvrez cette formation pour développer vos compétences.'}
              </p>
              <div className="course-footer">
                <span className={`certification-badge ${course.is_certifiant ? 'certified' : 'non-certified'}`}>
                  {course.is_certifiant ? 'Certifiante' : 'Non certifiante'}
                </span>
                <a
                  href={`${baseURL}/section/cour/${course.id}`}
                  className="course-link"
                >
                  Voir détails
                </a>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Formation;