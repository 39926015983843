import React, { useState } from 'react';
// import './FAQSection.scss';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqItems = [
    {
      question: "Qu'est-ce que Certy Up ?",
      answer: "CertyUp est une plateforme en ligne proposant des micro-certifications et des formations certifiantes courtes, conçues pour doter les apprenants de compétences recherchées sur le marché du travail."
    },
    {
      question: "Comment m'inscrire à une formation ?",
      answer: "Pour vous inscrire, créez un compte sur notre site, explorez notre catalogue de formations et de certifications, sélectionnez celle qui vous intéresse et suivez les instructions pour finaliser votre inscription."
    },
    {
      question: "Quelles sont les formations disponibles ?",
      answer: "Pour vous inscrire, créez un compte sur notre site, explorez notre catalogue de formations et de certifications, sélectionnez celle qui vous intéresse et suivez les instructions pour finaliser votre inscription."
    },
    {
      question: "Les formations sont-elles payantes ?",
      answer: "Nos formations non certifiantes sont toutes accessibles via un abonnement :\n• Abonnement mensuel : 6 000 FCFA\n• Abonnement annuel : 50 000 FCFA\nUne fois abonné, vous accédez à l'ensemble de la plateforme de cours en ligne.\nNos Certifications sont quant à elles payantes, avec un tarif spécifique à chaque certification. Les détails des tarifs sont indiqués sur la page de chaque certification."
    },
    {
      question: "Comment payer ma formation ?",
      answer: "Les paiements peuvent être effectués en ligne via une plateforme de paiement mobile (PayDunya). Les instructions détaillées sont fournies lors de l'inscription."
    },
    {
      question: "Puis-je suivre plusieurs formations en même temps ?",
      answer: "Absolument ! Vous pouvez suivre autant de formations que vous le souhaitez, en fonction de votre disponibilité et de vos objectifs d'apprentissage."
    },
    {
      question: "Comment puis-je contacter le support en cas de problème ?",
      answer: "Si vous rencontrez des difficultés ou avez des questions, vous pouvez nous contacter via le formulaire de contact sur notre site ou nous appeler au +221 77 171 58 58."
    }
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2 className="faq-section__title">Questions fréquemment posées</h2>
      <p className="faq-section__subtitle">Tout ce que vous devez savoir sur CertyUp</p>

      <div className="faq-section__items">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item">
            <div 
              className="faq-item__question" 
              onClick={() => toggleFAQ(index)}
            >
              {item.question}
              <span className={`faq-item__icon ${activeIndex === index ? 'active' : ''}`}>
                {activeIndex === index ? '−' : '+'}
              </span>
            </div>
            <div className={`faq-item__answer ${activeIndex === index ? 'active' : ''}`}>
              {item.answer}
            </div>
            <div className="faq-item__divider"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;