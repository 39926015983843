import React from 'react';
import Footer from '../components/fotter';
import Header from '../components/header';
import Formation from '../components/Formation';
import Fille from './formation/Fille';
import FrenchBanner from '../components/FrenchBanner';
import Offre from '../components/Offre';

// Pour les données statiques
const themes = require('../configs/fomations.json');

const FormationPage = () => {
  return (
    <div className="formation_page_section">
      <Header domaines={themes.formations} />
      <Fille />
      <FrenchBanner />
      <Formation />
      <Offre/>
      <Footer />
    </div>
  );
};

export default FormationPage;