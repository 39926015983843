import React from 'react';
function Fille() {
  return (
    <div 
      className="banner-background" 
      style={{
        width: '100%',
        height: '20%',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <img
        src="../../img/Background.png"
        alt="Background banner"
        className="background-image"
        style={{
          width: '100%',
          height: '10%',
          objectFit: 'cover',
          backgroundColor: '#1a237e',
          borderRadius: '8px'
        }}
      />
    </div>
  );
}

export default Fille;