import Bosser from "../components/Bosser";

import Contact from "../components/contact";
import DomaineSection from "../components/domaineSection";
import CertyUpExact from "../components/CertyUpExact";
// import TrainingBanner from "../components/TrainingBanner";

import Footer from "../components/fotter";
import Header from "../components/header";
import HeaderSection from "../components/HeaderSection";
import Certifier from "../components/Certifier";
import Offre from "../components/Offre";
import Parteners from "../components/parteners";
import WhatsAppWidget from "../components/WhatsAppWidget";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import BannierPub from "../components/BannierPub";
import TrainingBanner from "../components/TrainingBanner";
import FAQSection from "../components/FAQSection";
import WhatsAppContact from "../components/WhatsAppContact";
const themes = require('../configs/fomations.json')
function Accueil() {
   const location = useLocation();

   useEffect(()=>{
    if (location.hash){
        const section = document.querySelector(location.hash);
        if(section){
            section.scrollIntoView({ behavior : 'smooth'});
        }
    }
   },[location]);
  

    return ( 
        <>
            <Header domaines={themes.formations} />
            <HeaderSection />
            <CertyUpExact/>
            <BannierPub/>
            <TrainingBanner/>
            <DomaineSection domaines={themes.formations} />
            <Certifier/>
            {/* <Offre/> */}
            <Parteners /> 
            <Bosser/>
            <div style={{borderBottom:"4px solid #F78323"}} className="mx-5 mb-2"></div>
            <FAQSection/>
            <WhatsAppContact/>
            <Contact />
            <div style={{borderBottom:"4px solid #F78323"}} className="mb-2"></div>
            <Footer />
            <WhatsAppWidget/>
        </>
     );
}

export default Accueil;