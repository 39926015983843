import React from 'react';
import '../styles/FrenchBanner.scss';

const FrenchBanner = () => {
  return (
    <div className="french-banner">
      <div className="french-banner__content">
        <h2 className="french-banner__title">
          <span className="french-banner__text">APPRENEZ À VOTRE RYTHME,</span>
          <span className="french-banner__highlight">ÉVOLUEZ SANS LIMITES !</span>
        </h2>
      </div>
    </div>
  );
};

export default FrenchBanner;