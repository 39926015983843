import React from 'react';

const WhatsAppContact = () => {
  const phoneNumber = '+221771715858';
  const message = 'Bonjour Neolean Academy'; 

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  return (
    <div className="whatsapp-contact">
      <div className="whatsapp-contact__icon">
        <img src="/img/Vector.png" alt="WhatsApp" />
      </div>
      
      <div className="whatsapp-contact__content">
        <h2 className="whatsapp-contact__title">Vous avez encore des questions ?</h2>
        <p className="whatsapp-contact__description">
          Vous ne trouvez pas la réponse que vous cherchez ? Veuillez discuter avec notre équipe.
        </p>
        
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <button className="whatsapp-contact__button">
            Entrer en contact
          </button>
        </a>
      </div>
    </div>
  );
};

export default WhatsAppContact;