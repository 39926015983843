import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import '../styles/TrainingBanner.scss'; // Ajustez le chemin selon la position de votre composant

const TrainingBanner = () => {
  const navigate = useNavigate();
  const [dureeChoisie, setDureeChoisie] = useState('1 mois');
  const [prixActuel, setPrixActuel] = useState("6000");
  const [descriptionActuelle, setDescriptionActuelle] = useState('');
  
  const changePage = () => {
    console.log(dureeChoisie);
    console.log(prixActuel);
    navigate("/je me forme", { "state": { "duree": dureeChoisie, "prix": prixActuel, "description": descriptionActuelle } });
  }
  
  return (
    <div className="training-banner">
      <div className="training-banner__content">
        <h2 className="training-banner__title">
          Rejoignez CertyUp et accédez à des formations pratiques, certifiantes et adaptées au marché du travail.
        </h2>
        <p className="training-banner__subtitle">
          Que vous souhaitiez apprendre une nouvelle compétence ou obtenir une certification reconnue, nous avons la solution pour vous !
        </p>
        <button
          className="training-banner__button"
          onClick={changePage}
        >
          S'inscrire Maintenant <span className="training-banner__button-arrow">›</span>
        </button>
      </div>
    </div>
  );
};

export default TrainingBanner;