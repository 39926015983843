
function Hero() {
  return (
    <div className="header_section mt-4 pb-3">
      <div className="container">
        <div className="row">
          <div
            className="col-md-7 textsection"
          >
            <div className="text">


              <h1 className="cv">Booste ton <span>CV</span>  et tes
                compétences avec les  </h1><h1 className="certif">
                <span> certifications</span> de CertyUP !</h1>
              {/* <div className="bloc4">
                <div className="search-container">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Dévelopeur Full Stack"
                  />
                  <button className="search-button">
                    <i className="fas fa-search"></i> Rechercher
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="col-md-5 imgsection text-center"
          >
            <div className="img">
              <img
                src="./img/Group20.png"
                alt=""
                className=""
              // style={{ maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
